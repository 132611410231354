.swiper {
  border-radius: 10px;
  width: 100%;
}

.swiper-slide {
  border-radius: 10px;
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  border-radius: 10px;
  display: block;
  width: 100%;
}